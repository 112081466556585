import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Hero} from '../components/Hero';
import {BaseURL, Token, hashScrollHandler} from '../utils/utils';

const dehydrated = {
    hero: [] as any[],
    title_1: "",
    links_1: [] as any[],
    text_1: "",
    partners_1: [] as any[],
    title_2: "",
    links_2: [] as any[],
    text_2: "",
    partners_2: [] as any[],
    carriers:[{name: "", description: "", url: "", links: [] as any[]}],
    repairTools:[{name: "", url: ""}]
}

const HelpPoint = (props: any) => {
  const language = props.language
  const [data, setData] = useState(dehydrated);
  const [repairToolUrlIdx, setRepairToolUrlIdx] = useState(0);
  //const [carrierIdx, setCarrierIdx] = useState(0);
  //const [isChecked, setChecked] = React.useState(false);
  //const [retourInfoClassName, setRetourInfoClassName] = React.useState("d-none");
  //const [carrierInfoClassName, setCarrierInfoClassName] = React.useState("");
  //const [acceptedTermsClassName, setClassAcceptedTerms] = React.useState("d-none");
  //const [unAcceptedTermsClassName, setClassUnAcceptedTerms] = React.useState("opacity-50");
  const queryParams = new URLSearchParams(window.location.search);
  const pathnameArr = props.location.pathname.split("/");
  const loc = pathnameArr.slice(-1)[0]||"default"

  /*const handleCarrierClick = (idx:any, name:any) => {
    if( name !== "dhl" && name !== "dpd" ) {
      setCarrierInfoClassName("d-none")
      setRetourInfoClassName("")
    } else {
      setCarrierIdx(idx)

      setCarrierInfoClassName("")
      setRetourInfoClassName("d-none") 
    }
  }*/

  /*const handleChange = () => { 
    setChecked(!isChecked);
    if( isChecked === false ) {
      setClassAcceptedTerms("")
      setClassUnAcceptedTerms("d-none")
    } else {
      setClassAcceptedTerms("d-none")
      setClassUnAcceptedTerms("opacity-50")
    }
  };*/

  useEffect(() => {
    async function fetchPage(){
      const allowedLocations = ["hannover"];
      var carrierFilter = "alle";
      if( allowedLocations.indexOf(loc) !== -1 ) {
        carrierFilter = loc;
      }
      const content = await axios.get(BaseURL + '/cockpit/api/singletons/get/online_services?token='+Token+'&lang='+language+'&populate=3');
      const carriers = await axios.get(BaseURL + '/cockpit/api/collections/get/carriers?token='+Token+'&lang='+language+'&filter[page]='+carrierFilter);
      const repairToolUrls = await axios.get(BaseURL + '/cockpit/api/collections/get/repairToolUrls?token='+Token+'&lang='+language);
      const repairTools = repairToolUrls.data.entries.sort((a: any,b: any) => parseInt(a.index) - parseInt(b.index));

      if( queryParams.get('manufacturer') !== null && queryParams.get('manufacturer') === "clearcom" ) {
        /* Annahme */   if( repairTools.length >= 1 ) repairTools[0]["url"] = "https://tools.sertronics.de/en/if/clearcom/index.php?mli";
        /* Auskunft */  if( repairTools.length >= 2 ) repairTools[1]["url"] = "https://tools.sertronics.de/en/if/clearcom/status.php?mli";
        /* Login */     if( repairTools.length >= 3 ) repairTools[2]["url"] = "https://tools.sertronics.de/en/if/clearcom/status.php?mli";
      }
      if( queryParams.get('custnr') !== null && queryParams.get('custpw') !== null ) {
        /* Auskunft */  if( repairTools.length >= 2 ) repairTools[1]["url"] = repairTools[1]["url"] + "&custnr=" + queryParams.get('custnr') + "&custpw=" + queryParams.get('custpw');
      }

      /* Seo URL to manufacturer, e.g. /help-point/t/Reparatur-und-Abholservice/FUJI--INSTAX%20100.html */
      if( loc.indexOf(".html") !== -1 && pathnameArr.length === 5 && pathnameArr[2] === "t" ) {
        const details = loc.replace(".html", "").split("--");
        repairTools[0]["url"] = repairTools[0]["url"] + "&man=" + details[0] +"&prod=" + details[1]
        hashScrollHandler("repair_booking")
      } else {
        hashScrollHandler()
      } 

      setData({
        ...content.data,
        carriers: carriers.data.entries.sort((a: any,b: any) => parseInt(a.index) - parseInt(b.index)),
        repairTools: repairTools
      })       
    }
    fetchPage()

    const bootstrapScript = document.createElement('script');
    bootstrapScript.src = "/tool/bootstrap.bundle.min.js?" + Date.now();
    bootstrapScript.async = true;
    document.body.appendChild(bootstrapScript);

    const bootstrapStyle = document.createElement('link');
    bootstrapStyle.rel = "stylesheet";
    bootstrapStyle.type = "text/css";
    bootstrapStyle.href = "/tool/bootstrap.modal.min.css?" + Date.now();
    document.body.appendChild(bootstrapStyle);

    const clickTabScript = document.createElement('script');
    clickTabScript.src = "/tool/clicktab.min.js?" + Date.now();
    clickTabScript.async = true;
    document.body.appendChild(clickTabScript);

    const iframeScript = document.createElement('script');
    iframeScript.src = "/tool/iframe.min.js?" + Date.now();
    iframeScript.async = true;
    document.body.appendChild(iframeScript);

    /* Seo URL to manufacturer, e.g. /help-point/t/Reparatur-und-Abholservice/FUJI--INSTAX%20100.html */
    if( loc.indexOf(".html") !== -1 && pathnameArr.length === 5 && pathnameArr[2] === "t" ) {
      hashScrollHandler("repair_booking")
    } else {
      hashScrollHandler()
    }
     // eslint-disable-next-line
  }, [language]);
  
  return(
  <div className="shop-content">
    <Hero data={data.hero} btn={false} language={language}/>
    <div className="container container-fluid" id="repair">
      {/* <div className="row">
        <div className="col-xs-12">
          <h2>{data.title_1}</h2>
        </div>
      </div> */}
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <h2>{data.title_1}</h2>
          <p>{data.text_1}</p>
          { ( language !=='de' && language !=='en' ) &&
            <div className="row">
              {data.links_1.map((link: any, idx: number) => (<div key={"first-bullet-button-"+idx}>
              {link.value.link ? 
              <a href={link.value.link}>
                <div className="online-button-link">
                  <div className="online-link-icon"><img  width={42} height={42} src={BaseURL + "/" + link.value.icon.path} alt=""/> </div><span dangerouslySetInnerHTML={{__html: link.value.heading}}/>
                </div>
              </a>
              :
              <div className="online-button-link">
                <div className="online-link-icon"><img  width={42} height={42}src={BaseURL + "/" + link.value.icon.path} alt=""/></div> <span dangerouslySetInnerHTML={{__html: link.value.heading}}/>
              </div>
              }
              </div>))}
            </div>
          }
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="row center-xs middle-xs">
            {data.partners_1.map((img: any, idx: number) => <img key={"partners_1_"+idx+img.path} src={BaseURL + "/" + img.path} style={{padding: "10px 30px"}} className="col-xs-4 col-sm-3" alt=""/>)}
          </div>
        </div>
      </div>
      { ( language ==='de' || language ==='en' ) && /* repair-tool-row */
        <>
        <div className="row">
          <div className="col-xs-12 col-md-6">
              <h3 className="location-heading-wrapper">
                {data.repairTools.map((repairToolUrl: any, idx: number) => (
                  <span 
                    className={idx===repairToolUrlIdx ? "location-heading active" : "location-heading"}
                    onClick={() => setRepairToolUrlIdx(idx)}
                    key={"repairToolUrl"+idx}
                    id={(idx===0)?"booking":(idx===1)?"info":"login"}
                  >
                    {repairToolUrl.name}
                  </span>
                ))}
              </h3>
              <iframe id="repair-iframe" title="repair-iframe" className="" src={data.repairTools[repairToolUrlIdx].url} scrolling="no" frameBorder={0}></iframe>
              <div id="iframe-loading" className="">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
              <div className="modal fade" id="repairModal">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Hilfe</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button> */}
                    </div>
                    <div className="modal-body">
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            <div className="col-xs-12 col-md-6 stretch-xs">
              {data.links_1.map((link: any, idx: number) => (<div key={"first-bullet-button-"+idx}>
                  {link.value.link ? 
                  <a href={link.value.link}>
                    <div className="online-button-link">
                      <div className="online-link-icon"><img  width={42} height={42} src={BaseURL + "/" + link.value.icon.path} alt=""/> </div><span dangerouslySetInnerHTML={{__html: link.value.heading}}/>
                    </div>
                  </a>
                  :
                  <div className="online-button-link">
                    <div className="online-link-icon"><img  width={42} height={42}src={BaseURL + "/" + link.value.icon.path} alt=""/></div> <span dangerouslySetInnerHTML={{__html: link.value.heading}}/>
                  </div>
                  }
                </div>))}
            </div>
        </div>
        </>
      }
    </div>
    <div className="hued-row">
      <div className="container container-fluid" id="retour">
        {/* <div className="row">
          <div className="col-xs-12 start-xs">
            <h2>{data.title_2}</h2>
          </div>
          </div> */}
        <div className="row">{/* reverse */}
          <div className="col-xs-12 col-md-6">
            <h2>{data.title_2}</h2>
            <p>{data.text_2}</p>
            { ( language !=='de' && language !=='en' ) &&
              <div className="row">
                {data.links_2.map((link: any, idx: number) => (<div key={"first-bullet-button-"+idx}>
                  {link.value.link ? 
                  <a href={link.value.link} rel="noopener noreferrer">
                    <div className="online-button-link">
                      <div className="online-link-icon-alt"><img  width={42} height={42} src={BaseURL + "/" + link.value.icon.path} alt=""/> </div><span dangerouslySetInnerHTML={{__html: link.value.heading}}/>
                    </div>
                  </a>
                  :
                  <div className="online-button-link">
                    <div className="online-link-icon-alt"><img  width={42} height={42}src={BaseURL + "/" + link.value.icon.path} alt=""/></div> <span dangerouslySetInnerHTML={{__html: link.value.heading}}/>
                  </div>
                  }
                </div>))}
              </div>
            }
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="row center-xs middle-xs">
              {data.partners_2.map((img: any, idx: number) => <img key={"partners_2_"+idx+img.path} src={BaseURL + "/" + img.path} style={{padding: "10px 30px"}} className="col-xs-4 col-sm-3" alt=""/>)}
            </div>
          </div>
        </div>
        { ( language ==='de' || language ==='en' ) && /* location-row */
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <a target="_blank" href="https://etsls.sertronics.de/retoure" rel="noopener noreferrer">
                <div className="online-button-link ml-0">
                  <div className="online-link-icon-alt"><img width={42} height={42} src={BaseURL + "/images/line-icons/049-exchange.svg"} alt=""/></div>
                  <span>
                    {(language==='de') ? "Zum Retourenportal" : ""}
                    {language==='en' ? "To the returns portal" : ""}
                  </span>
                </div>
              </a>
            </div>
            <div className="col-xs-12 col-md-4">
              <a href="/terms" rel="noopener noreferrer">
                <div className="online-button-link">
                  <div className="online-link-icon-alt">
                    <img width={42} height={42} src={BaseURL + "/images/line-icons/037-test-1.svg"} alt="" />
                  </div>
                  <span>AGB der Sertronics GmbH</span>
                </div>
              </a>
            </div>
            <div className="col-xs-12 col-md-4">
              <a href="javascript:;;" rel="noopener noreferrer">
                <div className="online-button-link">
                  <div className="online-link-icon-alt">
                    <img width={42} height={42} src={BaseURL + "/images/line-icons/026-support.svg"} alt="" />
                  </div>
                  <span>
                    <span style={{color: "#000000"}}>Tel.:</span> <a href="tel:+4968314880884">+49 6831 488 0 884</a>
                    <br />
                    <a href="mailto:info@sertronics.de">info@sertronics.de</a>
                  </span>
                </div>
              </a>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  )
}

export default HelpPoint